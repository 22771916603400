<template>
  <div>
    <img src="../../../assets/images/login/mail.png" width="80" alt="" />
    <h1 class="mt-3 mb-0">{{ $t("success") }} !</h1>
    <span
      >{{ $t("emailSend") }} {{ this.userEmail }}.
      {{ $t("pleaseCheckYourEmail") }}
    </span>
    <v-col cols="12">
      <v-btn
        color="#59D79D"
        small
        class="white--text"
        @click="$router.push({ name: 'auth1.sign-in1' })"
      >
        {{ $t("backToHome") }}
      </v-btn>
    </v-col>
  </div>
</template>
<script>
export default {
  name: 'ConfirmMail1',
  props: {
    email: {
      type: String
    }
  },
  data () {
    return {
      userEmail: this.email
    };
  }
};
</script>
